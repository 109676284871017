export function fileListFromArray(files: File[]): FileList {
  const dataTransfer = new DataTransfer()
  files.forEach(file => dataTransfer.items.add(file))
  return dataTransfer.files
}

const hmUserEmails = ['ejaypcanaria@gmail.com','shirtstorehouse777@gmail.com']

export function isHMUser(email: string) {
  return hmUserEmails.includes(email)
}
