//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Grid, Typography } from '@mui/joy'
import { useParams, useNavigate } from 'react-router-dom'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { GET_JOB_CATEGORY } from '../../graphql/job-category-queries'
import { GET_JOBS } from '../../graphql/job-queries'
import { GET_CITY } from '../../graphql/location-queries.ts'
import { JobAttributes } from '../../types/job-type'
import { CREATE_JOB } from '../../graphql/job-queries'
import { GET_CURRENT_CUSTOMER } from '../../graphql/user-queries'
import JobForm from '../../components/JobForm.tsx'

export default function NewJobPage() {
  const navigate = useNavigate()
  const { categorySlug, locationId } = useParams()

  const { data: { city }} = useSuspenseQuery(GET_CITY, { variables: { id: locationId! } })
  const { data: { jobCategory }} = useSuspenseQuery(GET_JOB_CATEGORY, { variables: { slug: categorySlug! } })
  const [createJob, { loading: formSubmitting }] = useMutation(CREATE_JOB, {
    refetchQueries: [GET_CURRENT_CUSTOMER, { query: GET_JOBS }]
  })

  function onSubmit(data: JobAttributes) {
    const job = { ...data, cityId: city!.id, jobCategoryId: jobCategory!.id }
    createJob({
      variables: { attributes: job },
      onCompleted: () => navigate('/customer/jobs'),
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography level="h1">New Job</Typography>
      </Grid>
      <Grid xs={12} lg={8} sx={{ mb: '20px' }}>
        <JobForm onSubmit={onSubmit} saving={formSubmitting} submitLabel="Get Estimates" city={city} jobCategory={jobCategory} />
      </Grid>
    </Grid>
  )
}
