//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box, Button, Grid, Link } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_JOB_ESTIMATE } from '../../graphql/estimate-queries'
import { Typography } from '@mui/joy'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import EmailIcon from '@mui/icons-material/Email'
import { colors, fontSizes } from '../../layouts/Theme'
import DisplayMinMax from '../../components/DisplayMinMax'
import DisplayAvailability from '../../components/DisplayAvailability'
import BusinessLogo from '../../components/BusinessLogo'
import dayjs from 'dayjs'
import { ChevronLeft } from '@mui/icons-material'
import ProjectCard from '../../components/ProjectCard'

export default function ViewEstimatePage() {
  const navigate = useNavigate()
  const { jobId, estimateId } = useParams()
  const { data: { jobEstimate: {
    estimateMin,
    estimateMax,
    availability,
    details,
    createdAt,
    businessUser: { businessProfile },
    businessProjects,
  } } } = useSuspenseQuery(GET_JOB_ESTIMATE, { variables: { jobId: jobId!, estimateId: estimateId! } })

  return (
    <Grid container>
      <Grid xs={12}>
        <Button sx={{ mb: 3, pl: '3px' }} color="neutral" variant="outlined" onClick={() => navigate(`/customer/job/${jobId}`)}>
          <ChevronLeft />
          Back
        </Button>
      </Grid>
      <Grid xs={12} lg={7}>
        <Box sx={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '20px', mb: 2 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box>
              <Box sx={{ fontSize: fontSizes.regular, fontWeight: 600 }}>
                Estimate from {businessProfile.name}
              </Box>
              <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2, mt: '-2px' }}>
                Received {dayjs(createdAt).fromNow()}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', mt: 2, gap: 1 }}>
            <DisplayMinMax valueMin={estimateMin} valueMax={estimateMax} />
            <DisplayAvailability availability={availability} /> 
          </Box>
          {details &&
            <Box sx={{ mt: 2 }}>
              <Typography level="title-sm" sx={{ mb: 1, color: colors.gray2 }}>Message</Typography>
              <Box sx={{ padding: '10px 15px', bgcolor: colors.background, borderRadius: '12px', whiteSpace: 'pre-wrap' }}>{details}</Box>
            </Box>}
        </Box>
      </Grid>
      <Grid xs={12} lg={7}>
        <Box sx={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '20px', mb: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
            <BusinessLogo logoUrl={businessProfile.logoUrl} size="60px" />
            <Box>
              <Typography level="title-lg">{businessProfile.name}</Typography>
              <Typography level="body-md" sx={{ color: colors.gray2 }}>Established {businessProfile.yearEstablished}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mb: '2px', pl: 1 }}>
            <PhoneIphoneIcon sx={{ color: colors.gray1 }}/><Link sx={{ color: colors.gray3 }} href={`tel:${businessProfile.contactNumber}`} >{businessProfile.contactNumber}</Link>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mb: 1, pl: 1 }}>
            <EmailIcon sx={{ color: colors.gray1 }}/><Link sx={{ color: colors.gray3 }} href={`mail:${businessProfile.email}`}>{businessProfile.email}</Link>
          </Box>
          <Box sx={{ mt: 3, pl: 1, color: colors.gray3 }}>
            <Typography level="title-sm" sx={{ color: colors.gray2 }}>Social Media</Typography>
            {businessProfile.socialMediaLink}
          </Box>
          <Box sx={{ mt: 1, pl: 1, color: colors.gray3 }}>
            <Typography level="title-sm" sx={{ color: colors.gray2 }}>Address</Typography>
            {businessProfile.address}
          </Box>
          <Box sx={{ mt: 1, pl: 1, color: colors.gray3 }}>
            <Typography level="title-sm" sx={{ color: colors.gray2 }}>Business description</Typography>
            <Box sx={{ whiteSpace: 'pre-wrap' }}>{businessProfile.summary}</Box>
          </Box>
          {!!businessProjects.length &&
            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid xs={12}>
                <Typography level="h3">Business Portfolio</Typography>
              </Grid>
              {businessProjects.map((project) => (
                <Grid xs={12} md={6} key={project.id}>
                  <ProjectCard project={project} />
                </Grid>
              ))}
            </Grid>}
        </Box>
      </Grid>
    </Grid>
  )
}
