//@ts-expect-error: This is needed by Rails
import React from "react"
import { Navigate, createBrowserRouter, useRouteError } from 'react-router-dom'
import LeadsPage from "./pages/business/LeadsPage"
import EstimatesPage from "./pages/business/EstimatesPage"
import ProfilePage from "./pages/business/ProfilePage"
import BusinessLayout from "./layouts/business/Layout"
import CustomerLayout from "./layouts/customer/Layout"
import GetEstimatesPage from "./pages/customer/GetEstimatesPage"
import JobsPage from "./pages/customer/JobsPage"
import NewJobPage from "./pages/customer/NewJobPage"
import JobPage from './pages/customer/JobPage'
import ViewEstimatePage from "./pages/customer/ViewEstimatePage"
import CustomerNotificationsPage from "./pages/customer/NotificationsPage"
import BusinessNotificationsPage from "./pages/business/NotificationsPage"
import LeadPage from "./pages/business/LeadPage"
import EditJobPage from "./pages/customer/EditJobPage"
import CoveragePage from "./pages/business/CoveragePage"
import { Box } from "@mui/joy"
import DiscardedLeadsPage from "./pages/business/DiscardedLeadsPage"
import OnboardingLocationPage from "./pages/business/onboarding/LocationPage"
import OnboardingLayout from "./pages/business/onboarding/Layout"
import OnboardingCategoryPage from "./pages/business/onboarding/CategoryPage"
import OnboardingProfilePage from "./pages/business/onboarding/ProfilePage"
import BusinessMenu from "./components/BusinessMenu"
import CustomerMenu from "./components/CustomerMenu"

function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 20, height: '100vh' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ fontSize: '50px' }}>Page Not Found</Box>
        <Box>This is not the page you are looking for</Box>
      </Box>
    </Box>
  )
}

export const router = createBrowserRouter([
  {
    element: <BusinessLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/business/menu',
        element: <BusinessMenu />
      },
      {
        path: '/business/notifications',
        element: <BusinessNotificationsPage />
      },
      {
        path: '/business/leads',
        element: <LeadsPage />
      },
      {
        path: '/business/leads/:jobUuid',
        element: <LeadPage />
      },
      {
        path: '/business/estimates',
        element: <EstimatesPage />
      },
      {
        path: '/business/discarded-leads',
        element: <DiscardedLeadsPage />
      },
      {
        path: '/business/coverage',
        element: <CoveragePage />
      },
      {
        path: '/business/profile',
        element: <ProfilePage />
      },
      {
        path: '/business/onboarding',
        element: <Navigate to="/business/onboarding/location" />
      },
      {
        element: <OnboardingLayout />,
        children: [
          {
            path: '/business/onboarding/location',
            element: <OnboardingLocationPage />
          },
          {
            path: '/business/onboarding/categories',
            element: <OnboardingCategoryPage />
          },
          {
            path: '/business/onboarding/profile',
            element: <OnboardingProfilePage />
          },
        ]
      }
    ]
  },
  {
    element: <CustomerLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/customer/menu',
        element: <CustomerMenu />
      },
      {
        path: '/customer/notifications',
        element: <CustomerNotificationsPage />
      },
      {
        path: '/customer/get-estimates',
        element: <GetEstimatesPage />
      },
      {
        path: '/customer/jobs',
        element: <JobsPage />
      },
      {
        path: '/customer/job/:jobId',
        element: <JobPage />
      },
      {
        path: '/customer/job/:jobId/edit',
        element: <EditJobPage />
      },
      {
        path: '/customer/job/:jobId/estimate/:estimateId',
        element: <ViewEstimatePage />
      },
      {
        path: '/customer/new_job/:categorySlug/location/:locationId',
        element: <NewJobPage />
      },
    ]
  }
])
