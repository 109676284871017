//@ts-expect-error: This is needed by Rails
import React from 'react'
import ColorLensIcon from '@mui/icons-material/ColorLens'
import ArchitectureIcon from '@mui/icons-material/Architecture'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork'
import HardwareIcon from '@mui/icons-material/Hardware'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import ChairIcon from '@mui/icons-material/Chair'
import CurtainsIcon from '@mui/icons-material/Curtains'
import GrassIcon from '@mui/icons-material/Grass'
import FormatPaintIcon from '@mui/icons-material/FormatPaint'
import ShowerIcon from '@mui/icons-material/Shower'
import PowerIcon from '@mui/icons-material/Power'
import PestControlIcon from '@mui/icons-material/PestControl'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import SolarPowerIcon from '@mui/icons-material/SolarPower'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import CameraIndoorIcon from '@mui/icons-material/CameraIndoor'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import KitchenIcon from '@mui/icons-material/Kitchen'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import SensorDoorIcon from '@mui/icons-material/SensorDoor'
import RoofingIcon from '@mui/icons-material/Roofing'
import HandymanIcon from '@mui/icons-material/Handyman'
import FenceIcon from '@mui/icons-material/Fence'
import WaterIcon from '@mui/icons-material/Water'
import PoolIcon from '@mui/icons-material/Pool'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'

export function renderIcon(slug: string, fontSize = '30px') {
  const style = { fontSize }

  switch(slug) {
    case "waterproofing":
      return <WaterIcon sx={style} />
    case "swimming-pool":
      return <PoolIcon sx={style} />
    case "fire-safety-equipments":
      return <LocalFireDepartmentIcon sx={style} />
    case "gates-and-fences":
      return <FenceIcon sx={style} />
    case "installation-services":
      return <HandymanIcon sx={style} />
    case "roofing":
      return <RoofingIcon sx={style} />
    case "door-and-windows":
      return <SensorDoorIcon sx={style} />
    case "smart-devices":
      return <TipsAndUpdatesIcon sx={style} />
    case "interior-design":
      return <ColorLensIcon sx={style}/>
    case "architecture-design":
      return <ArchitectureIcon sx={style}/>
    case "construction":
      return <MapsHomeWorkIcon sx={style}/>
    case "renovation":
      return <HardwareIcon sx={style}/>
    case "finishing-materials":
      return <MeetingRoomIcon sx={style}/>
    case "glass-steel-aluminum":
      return <SquareFootIcon sx={style}/>
    case "furniture":
      return <ChairIcon sx={style}/>
    case "curtain":
      return <CurtainsIcon sx={style}/>
    case "gardening-and-landscaping":
      return <GrassIcon sx={style}/>
    case "paint":
      return <FormatPaintIcon sx={style}/>
    case "plumbing":
      return <ShowerIcon sx={style}/>
    case "electrical":
      return <PowerIcon sx={style}/>
    case "pest-control":
      return <PestControlIcon sx={style}/>
    case "water-filtration":
      return <FilterAltIcon sx={style}/>
    case "solar-panel":
      return <SolarPowerIcon sx={style}/>
    case "cleaning-services":
      return <CleaningServicesIcon sx={style}/>
    case "aircon-services":
      return <AcUnitIcon sx={style}/>
    case "cctv":
      return <CameraIndoorIcon sx={style}/>
    case "lighting":
      return <LightbulbIcon sx={style}/>
    case "appliance-repair":
      return <KitchenIcon sx={style}/>
  }
}
