//@ts-expect-error: This is needed by Rails
import React, { useState } from 'react'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/joy/Grid'
import { GET_JOBS } from '../../graphql/job-queries'
import { useSuspenseQuery } from '@apollo/client'
import Box from '@mui/joy/Box'
import { fontSizes, colors } from '../../layouts/Theme'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Button, Dropdown, IconButton, Menu, MenuButton, MenuItem } from '@mui/joy'
import MarkAsCompletedModal from '../../components/MarkAsCompletedModal'
import ImagesPreview from '../../components/ImagesPreview'
import CategoryIcon from '../../components/CategoryIcon'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import CircleIcon from '@mui/icons-material/Circle'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import WorkIcon from '@mui/icons-material/Work'
dayjs.extend(relativeTime)

export default function JobsPage() {
  const { data: { jobs } } = useSuspenseQuery(GET_JOBS)
  const navigate = useNavigate()
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null)
  const [modalOpen, setModalOpen] = useState(false)

  function openModal(jobId: string) {
    setSelectedJobId(jobId)
    setModalOpen(true)
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>
          Jobs
        </Typography>
      </Grid>
      {selectedJobId && <MarkAsCompletedModal open={modalOpen} setOpen={setModalOpen} jobId={selectedJobId!} />}
      <Grid xs={12} md={9} lg={6}>
        {jobs.length ?
          jobs.map(({ id, summary, jobCategory, images, status, createdAt, estimatesCount }) => {
            return (
              <Box
                key={id}
                sx={{
                  position: 'relative',
                  backgroundColor: '#FFF',
                  padding: '20px',
                  mb: 2,
                  borderRadius: '20px'
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <CategoryIcon categorySlug={jobCategory.slug} />
                  <Box>
                    <Box sx={{ fontSize: fontSizes.regular, fontWeight: 600 }}>{summary}</Box>
                    <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2 }}>
                      Posted {dayjs(createdAt).fromNow()}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', mt: 2, gap: 1, fontSize: fontSizes.sm }}>
                  <Box sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', color: colors.gray3, border: `1px solid ${colors.gray1}`, borderRadius: '16px', pl: '6px', pr: '12px', height: '30px' }}>
                    <CircleIcon sx={{ mr: '5px', fontSize: '14px', color: status === 'active' ? colors.green : colors.gray1 }} />{status}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', color: colors.gray3, border: `1px solid ${colors.gray1}`, borderRadius: '16px', pl: '6px', pr: '12px', height: '30px' }}>
                    <RequestQuoteIcon sx={{ mr: '5px', fontSize: '20px', color: colors.gray1 }} />Estimates: {estimatesCount}
                  </Box>
                </Box>
                <Dropdown>
                  <MenuButton sx={{ position: 'absolute', right: '10px', top: '10px' }} slots={{ root: IconButton }} slotProps={{ root: { variant: 'plain', color: 'neutral' }}}>
                    <MoreVertIcon />
                  </MenuButton>
                  <Menu sx={{ width: '150px' }} placement="bottom-end">
                    <MenuItem onClick={() => navigate(`/customer/job/${id}/edit`)}>Edit</MenuItem>
                  </Menu>
                </Dropdown>
                <ImagesPreview images={images} />
                {status === 'active' &&
                  <Box sx={{ mt: 3, display: 'flex', gap: 1 }}>
                    <Button onClick={() => navigate(`/customer/job/${id}`)}>View Estimates</Button>
                    <Button variant="outlined" color="neutral" onClick={(e) => { openModal(id); e.stopPropagation()}}>
                      Mark as Completed
                    </Button>
                  </Box>}
              </Box>
            ) 
        }) :
        <Box sx={{ p: '30px 20px 30px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ textAlign: 'center' }}>
            <WorkIcon sx={{ fontSize: '30px', color: colors.gray2 }}/>
            <p>You have not added any jobs yet</p>
            <Button variant="outlined" color="neutral" onClick={() => navigate('/customer/get-estimates')}>Post a Job</Button>
          </Box>
        </Box>}
      </Grid>
    </Grid>
  )
}
