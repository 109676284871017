import { TypedDocumentNode, gql } from "@apollo/client";
import BusinessProfileType, { UpdateBusinessProfileAttributes, UpdateCategoryIds, UpdateCoverageAttributes, UpdateLocationIds } from "../types/business-profile-type";

export const UPDATE_BUSINESS_PROFILE: TypedDocumentNode<{ updateBusinessProfile: { errors: string[], businessProfile: BusinessProfileType }}, { attributes: UpdateBusinessProfileAttributes | UpdateCoverageAttributes | UpdateLocationIds | UpdateCategoryIds }> = gql`
  mutation UpdateBusinessProfile($attributes: BusinessProfileAttributes!) {
    updateBusinessProfile(input: { attributes: $attributes }) {
      errors
      businessProfile {
        id
        name
        yearEstablished
        contactNumber
        email
        address
        socialMediaLink
        completed
        coverageCompleted
        summary
        logoUrl
        coveredLocations {
          id
          name
          provinceId
          provinceName
        }
        coveredCategories {
          id
          name
          slug
          description
        }
      }
    }
  }
`

export const GET_COVERAGE_SETTINGS: TypedDocumentNode<{ businessProfile: Pick<BusinessProfileType, 'id' | 'coveredLocations' | 'coveredCategories'> }> = gql`
  query GetCoverageSettings {
    businessProfile {
      id
      coveredLocations {
        id
        name
        provinceId
        provinceName
      }
      coveredCategories {
        id
        name
        slug
        description
      }
    }
  }
`

export const GET_BUSINESS_PROFILE: TypedDocumentNode<{ businessProfile: BusinessProfileType }> = gql`
  query GetBusinessProfile {
    businessProfile {
      id
      name
      yearEstablished
      contactNumber
      email
      address
      socialMediaLink
      completed
      coverageCompleted
      summary
      logoUrl
      coveredLocations {
        id
        name
        provinceId
        provinceName
      }
      coveredCategories {
        id
        name
        slug
        description
      }
    }
  }
`
