//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Grid, Typography, Box, Breadcrumbs, Button } from '@mui/joy'
import { GET_JOB } from '../../graphql/job-queries'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { colors, fontSizes } from '../../layouts/Theme'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import DisplayMinMax from '../../components/DisplayMinMax'
import BusinessLogo from '../../components/BusinessLogo'
import DisplayAvailability from '../../components/DisplayAvailability'
import { ChevronLeft } from '@mui/icons-material'

export default function JobPage() {
  const navigate = useNavigate()
  const { jobId } = useParams()
  const { data: { job } } = useSuspenseQuery(GET_JOB, { variables: { id: jobId || '' } })
  const { summary, estimates, estimatesCount } = job

  return (
    <Grid container>
      <Grid xs={12}>
        <Button sx={{ mb: 3, pl: '3px' }} color="neutral" variant="outlined" onClick={() => navigate(`/customer/jobs`)}>
          <ChevronLeft />
          Back
        </Button>
      </Grid>
      <Grid xs={12}>
        <Typography level="h2" sx={{ mb: 2 }}>Estimates Received ({estimatesCount})</Typography>
      </Grid>
      {estimates.length ?
        <>
          {estimates.map(({ id, createdAt, availability, estimateMin, estimateMax, businessUser: { businessProfile: { name, logoUrl }} }) => (
            <Grid xs={12} lg={7} key={id}> 
              <Box sx={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '20px', mb: 2 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <BusinessLogo logoUrl={logoUrl} />
                  <Box>
                    <Box sx={{ fontSize: fontSizes.regular, fontWeight: 600 }}>
                      {name}
                    </Box>
                    <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2, mt: '-2px' }}>
                      Received {dayjs(createdAt).fromNow()}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', mt: 2, gap: 1, flexWrap: 'wrap' }}>
                  <DisplayMinMax valueMin={estimateMin} valueMax={estimateMax} />
                  <DisplayAvailability availability={availability} /> 
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Button onClick={() => navigate(`/customer/job/${jobId}/estimate/${id}`)}>View</Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </> :
        <Box sx={{ mt: '10px' }}>There is no estimates yet.</Box>}
    </Grid>
  )
}
