//@ts-expect-error: This is needed by Rails
import React, { useState } from 'react'
import { Grid, FormLabel, FormControl, Input, Textarea, Button, Select, Option, RadioGroup, Radio, Sheet, Box, Typography } from '@mui/joy'
import JobType, { JobAttributes, JobTimeline } from '../types/job-type'
import { colors } from '../layouts/Theme'
import NumericFormatAdapter from './NumericFormatAdapter'
import { Controller, useForm } from 'react-hook-form'
import ImageUploadPreview from './ImageUploadPreview'
import ImageUploadButton from './ImageUploadButton'
import { fileListFromArray } from '../utils/general-utils'
import CategoryIcon from './CategoryIcon'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import JobCategoryType from '../types/job-category-type'
import CityType from '../types/city-type'

type BudgetType = 'withoutBudget' | 'withBudget'

interface Props {
  onSubmit: (data: JobAttributes) => void
  saving: boolean
  submitLabel: string
  job?: JobType
  jobCategory: JobCategoryType
  city: CityType
}

export default function JobForm({ 
  onSubmit, 
  saving = false, 
  submitLabel,
  jobCategory,
  city,
  job
}: Props) {

  const initialBudgetType = job?.budgetMin ? 'withBudget' : 'withoutBudget'
  const [imagePreviews, setImagePreviews] = useState<string[]>([])
  const [budgetType, setBudgetType] = useState<BudgetType>(initialBudgetType)
  const { register, handleSubmit, control, getValues, setValue } = useForm<JobAttributes>({
    defaultValues: {
      description: job?.description,
    }
  })

  function removeImage(index: number) {
    const currentFiles = getValues('fileList')
    if (!currentFiles) return

    const newImagePreviews = imagePreviews.filter((_, i) => i !== index)
    const newFiles = Array.from(currentFiles).filter((_, i) => i !== index)

    setValue('fileList', fileListFromArray(newFiles))
    setImagePreviews(newImagePreviews)
  }

  return (
    <Box sx={{ p: '20px', backgroundColor: '#FFF', borderRadius: '20px' }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Box sx={{ display: 'flex' }}>
          <CategoryIcon categorySlug={jobCategory.slug} />
        </Box>
        <Box>
          <Typography level="title-lg">{jobCategory.name}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LocationOnIcon sx={{ fontSize: '20px', ml: '-5px', color: colors.gray2 }}/>
            <Typography level="body-sm">{city.name}</Typography>
          </Box>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid xs={12} md={6}>
            <FormControl sx={{ mt: 2, mb: 1 }}>
              <FormLabel>Timeline</FormLabel>
              <Controller
                name="timeline"
                control={control}
                defaultValue={job?.timeline || JobTimeline.asap}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={(_e, val) => onChange(val)}
                  >
                    <Option value={JobTimeline.asap}>As soon as possible</Option>
                    <Option value={JobTimeline.inWeeks}>1-3 weeks from now</Option>
                    <Option value={JobTimeline.inMonths}>1-3 months from now</Option>
                    <Option value={JobTimeline.inHalfYear}>6-12 months from now</Option>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid xs={12}>
            <FormLabel sx={{ mb: 1 }}>
              Budget Option
            </FormLabel>
            <RadioGroup
              orientation="horizontal"
              value={budgetType}
              onChange={({ target: { value }}) => setBudgetType(value as BudgetType)}
              sx={{ gap: 1.5 }}
            >
              {["withoutBudget", "withBudget"].map((value) => (
                <Sheet
                  key={value}
                  sx={{
                    p: '7px 15px',
                    borderRadius: 'md',
                    width: {
                      xs: '100%',
                      md: '50%',
                    }
                  }}
                >
                  <Radio
                    overlay
                    disableIcon
                    label={value === 'withBudget' ? 'I have a budget in mind' : "I don't have a budget in mind"}
                    value={value}
                    slotProps={{
                      label: ({ checked }) => ({
                        sx: {
                          fontWeight: checked ? 500 : 'initial',
                          color: checked ? colors.primary : colors.fontColor
                        }
                      }),
                      action: ({ checked }) => ({
                        sx: {
                          '&:hover': {
                            borderColor: colors.primary,
                            backgroundColor: '#FFF',
                          },
                          ...(checked && {
                            inset: -1,
                            '--variant-borderWidth': '1px',
                            '&&': {
                              borderColor: colors.primary,
                            }
                          })
                        }
                      })
                    }}
                  />
                </Sheet>
              ))}
            </RadioGroup>
          </Grid>
          {budgetType === "withBudget" &&
            <>
              <Grid xs={12} md={4}>
                <FormControl>
                  <FormLabel>Lower range</FormLabel>
                  <Controller
                    name="budgetMin"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Input
                        startDecorator="₱"
                        defaultValue={job?.budgetMin}
                        onChange={(e) => onChange(+e.target.value)}
                        slotProps={{
                          input: {
                            component: NumericFormatAdapter,
                          },
                        }}
                        placeholder="1,000"
                        autoFocus
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} md={4}>
                <FormControl>
                  <FormLabel>Higher range</FormLabel>
                  <Controller
                    name="budgetMax"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Input
                        defaultValue={job?.budgetMax}
                        startDecorator="₱"
                        onChange={(e) => onChange(+e.target.value)}
                        slotProps={{
                          input: {
                            component: NumericFormatAdapter,
                          },
                        }}
                        placeholder="2,000"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid xs={0} md={4} />
            </>}
          <Grid xs={12} md={6} lg={4}>
            <FormLabel sx={{ mb: 1 }}>Images</FormLabel>
            <ImageUploadButton setImagePreviews={setImagePreviews} onChange={(files) => setValue('fileList', fileListFromArray(files))} />
            <ImageUploadPreview imagePreviews={imagePreviews} onRemove={removeImage} />
          </Grid>
          <Grid xs={12} md={9}>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea autoFocus {...register("description")} placeholder="Describe your job in details here" minRows={6}/>
            </FormControl>
          </Grid>
          <Grid xs={12}>
            <Button onClick={handleSubmit(onSubmit)} disabled={saving} sx={{ mr: '10px' }}>{submitLabel}</Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
