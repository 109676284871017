//@ts-expect-error: This is needed by Rails
import React, { useContext, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/joy'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { GET_BUSINESS_PROFILE, UPDATE_BUSINESS_PROFILE } from '../../graphql/business-profile-queries'
import LocationCoverage, { LocationOption, convertCitiesToLocations } from '../../components/LocationCoverage'
import JobCategoryType from '../../types/job-category-type'
import CategoryCoverage from '../../components/CategoryCoverage'
import { GET_LEADS } from '../../graphql/job-queries'
import { LayoutContext } from '../../layouts/LayoutContextProvider'

export default function CoveragePage() {
  const { data: { businessProfile: { coveredCategories, coveredLocations } } } = useSuspenseQuery(GET_BUSINESS_PROFILE)
  const [coveredLocationIds, setCoveredLocationIds] = useState<string[]>(coveredLocations.map(({id}) => id))
  const [selectedLocations, setSelectedLocations] = useState<LocationOption[]>(convertCitiesToLocations(coveredLocations))
  const [selectedCategories, setSelectedCategories] = useState<JobCategoryType[]>(coveredCategories)
  const { showAlert } = useContext(LayoutContext)

  const [updateBusinessProfile, { loading }] = useMutation(UPDATE_BUSINESS_PROFILE, {
    variables: { attributes: { coveredCategoryIds: selectedCategories.map(({id}) => id), coveredLocationIds } },
    refetchQueries: [{ query: GET_LEADS }],
    onCompleted: () => showAlert('Your coverage has been updated')
  })

  return (
    <Grid container>
      <Grid xs={12}>
        <Box sx={{ display: 'flex', mb: 2, justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography level="h1">Coverage</Typography>
          <Button sx={{ flexGrow: 0 }} onClick={() => updateBusinessProfile()} disabled={loading}>Save Changes</Button>
        </Box>
      </Grid>
      <Grid xs={12}>
        <Box sx={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '20px', mb: 2 }}>
          <Typography level="h3" sx={{ mb: 2 }}>Covered Categories</Typography>
          <LocationCoverage
            autoFocus
            locations={selectedLocations}
            onCityIdsChange={setCoveredLocationIds}
            onLocationsChange={setSelectedLocations}
          />
        </Box>
      </Grid>
      <Grid xs={12}>
        <Box sx={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '20px', mb: 2 }}>
          <Typography level="h3" sx={{ mb: 2 }}>Covered Locations</Typography>
          <CategoryCoverage
            categories={selectedCategories}
            onCategoriesChange={setSelectedCategories}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
