//@ts-expect-error: This is needed by Rails
import React, { useState, useEffect, useContext } from 'react'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/joy/Grid'
import Button from '@mui/joy/Button'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import CategoryCard from '../../components/CategoryCard'
import { useSuspenseQuery } from '@apollo/client'
import { GET_JOB_CATEGORIES } from '../../graphql/job-category-queries'
import JobCategoryType from '../../types/job-category-type'
import CityType from '../../types/city-type'
import { useNavigate } from 'react-router-dom'
import { CustomerContext } from '../../layouts/customer/CustomerContextProvider'
import LocationAutocomplete from '../../components/LocationAutocomplete'
import JobCategoryAutocomplete from '../../components/JobCategoryAutocomplete'
import { Box } from '@mui/joy'

type JobCategoriesProps = {
  selectedJobCategory: JobCategoryType | null,
  onClick: (jobCategory: JobCategoryType | null) => void
}

function JobCategories({ selectedJobCategory, onClick }: JobCategoriesProps) {
  const { data } = useSuspenseQuery(GET_JOB_CATEGORIES)

  return (
    <Grid container spacing={{ xs: 2, lg: 4 }} sx={{ mt: 2 }}>
      {data.jobCategories.map((jobCategory) => (
        <Grid xs={6} md={4} lg={3} key={jobCategory.id}>
          <CategoryCard
            jobCategory={jobCategory}
            selected={jobCategory.id === selectedJobCategory?.id}
            onClick={onClick}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default function GetEstimatesPage() {
  const navigate = useNavigate()
  const currentCustomer = useContext(CustomerContext)

  const [city, setCity] = useState<CityType | null>(currentCustomer?.lastCity || null)
  const [jobCategory, setJobCategory] = useState<JobCategoryType | null>(null)

  useEffect(() => {
    if (currentCustomer) {
      setCity(currentCustomer.lastCity)
    }
  }, [currentCustomer])

  function navigateToNewJobPage(jobCategorySlug = jobCategory?.slug) {
    navigate(`/customer/new_job/${jobCategorySlug}/location/${city?.id}`)
  }

  function handleJobCategoryClick(jobCategory: JobCategoryType | null) {
    if (city) {
      navigateToNewJobPage(jobCategory?.slug)
    } else {
      setJobCategory(jobCategory)
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 1 }}>
          Get Estimates
        </Typography>
      </Grid>
      <Grid xs={12} lg={9}>
        <Box sx={{ p: '20px 20px 30px', backgroundColor: '#FFF', borderRadius: '20px' }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              <FormControl>
                <FormLabel>Location</FormLabel>
                <LocationAutocomplete
                  onValueChange={(value) => setCity(value as CityType)}
                  value={city}
                  autoFocus={!city?.id ? true : undefined}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} md={4}>
              <FormControl>
                <FormLabel>Category</FormLabel>
                <JobCategoryAutocomplete
                  onValueChange={(value) => setJobCategory(value as JobCategoryType)}
                  value={jobCategory}
                  autoFocus={city?.id && !jobCategory?.id ? true : undefined}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} md={4}>
              <Box sx={{ display: 'flex', alignItems: 'end', height: '100%', mt: { xs: '5px', md: '0' } }}>
                <Button onClick={() => navigateToNewJobPage()}>
                  Get Started
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <JobCategories selectedJobCategory={jobCategory} onClick={handleJobCategoryClick}/>
    </Grid>
  )
}
